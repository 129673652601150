









































import Vue from 'vue';
import { endpoints } from '@/common/config';
import { putToClipboard } from '@/common/misc';
import SurveyComponent from '@/components/elements/SurveyComponent.vue';

export default Vue.extend({
    name: 'adminSurveyEdit',
    data(){
        return {
            isFetchingSurvey: false,
            urlVarIsChanged: false,
            chapterIsChanged: false,
        }
    },
    components: {
        SurveyComponent,
    },
    props: {
        survey: {type: Object},
        isSynced: {type: Boolean},
    },
    watch: {
        surveyChapter(){
            this.chapterIsChanged = true;
        },
        surveyUrlVar(){
            this.urlVarIsChanged = true;
        },
    },
    methods: {
        //Called from adminSurveyTab
        onSave(){
            this.chapterIsChanged = false;
            this.urlVarIsChanged = false;
        },
        copyLink(){
            const link = this.surveyUrlNoParam+this.survey.urlVar;
            if(!this.survey.serverUpdated){
                alert('Невозможно сформировать ссылку (опрос не сохранён на сервере).');
                return;
            }
            if(!this.survey.urlVar) {
                alert('Невозможно сформировать ссылку на опрос. Url параметр не указан.');
                return;
            }
            if(this.urlVarIsChanged) {
                alert('Невозможно сформировать ссылку на опрос (имеются несохранённые изменения).')
            }
            putToClipboard(link);
            // alert('Значение "'+link+'" скопировано в буфер обмена')
        },
        async fetchSurveyById(){
            this.isFetchingSurvey = true;
            try {
                const response = await fetch(endpoints.adminGetPublicSurvey(this.survey.apiSurveyId));
                const json = await response.json();
                this.$set( this.survey, 'jsonObj', JSON.stringify(json));
            } catch (error) {
                alert(error);
            } finally {
                this.isFetchingSurvey = false;
            }
        },
    },
    computed: {
        surveyUrlNoParam():string{
            return 'https://izibiz.club/quizzes?take_id=';
        },
        surveyUrlVar():string{
            return this.survey.urlVar;
        },
        surveyChapter():number{
            return this.survey.chapter;
        },
        highlightServerSaveDate():boolean{
            if(!this.survey.apiUpdated) return false;
            if(!this.survey.serverUpdated) return true;
            return this.survey.serverUpdated.getTime() < this.survey.apiUpdated.getTime();
        },
        tAreaJsonStr: {
            get():string{
                const surveyJsonStr = this.survey.jsonObj
                try {
                    return surveyJsonStr?.includes('\n') ? surveyJsonStr : JSON.stringify(JSON.parse(surveyJsonStr), null, 2);
                } catch (error) {
                    return surveyJsonStr ? error : "";
                }
            },
            cache: false
        }
    },
})
