

































































import { endpoints } from "@/common/config";
import StorageService from "@/common/storage.service";
import Vue, { VueConstructor } from "vue";
import AdminSurveyEdit from "@/components/admin/AdminSurveyEdit.vue";
import {serializeSurveyResults, TApiSurvey, TSurvey, TSurveyResult} from '@/common/classes/survey.manager';
import ApiService from "@/common/api.service";
import { processResponseError, downloadFile } from "@/common/misc";

const GMT3_MS_OFFSET = + (3*60*60*1000);

export default Vue.extend({
    name: "adminSurveyTab",
    components: {AdminSurveyEdit},
    watch: {
        surveyApiKey(v:string):void{
            StorageService
                .getStorageItems()
                .SURVEY_JS_API_KEY
                .setValue(v);
        }
    },
    data(){
        return {
            isSyncTab: true,
            surveyApiKey: StorageService.getStorageItems().SURVEY_JS_API_KEY.getValue() || "",
            surveyListSyncDate: undefined as undefined|Date,
            isFetchingSurveyList: false,
            isFetchingSaveSurveys: false,
            surveyResultsMap: {

            } as Record<string, TSurveyResult[]>,
            serverSurveyList: [
                // jsonObj: '{"pages":[{"name":"page1","elements":[{"type":"radiogroup","name":"question1","title":"2+2=","correctAnswer":"item2","choices":[{"value":"item1","text":"3"},{"value":"item2","text":"4"},{"value":"item3","text":"Без понятия"}]},{"type":"comment","name":"question2","title":"Что думаете о третьей главе?"}]}]}',
                // apiSurveyId: '10010199-2be2-49fa-9b3c-4f9f3e0ba34e',
                // urlVar: '',
                // syncName: 'Опрос третья глава',
                // apiUpdated: new Date('2021-04-09T21:53:28'),
                // serverUpdated: new Date('2021-03-07T21:53:28')
            ] as TSurvey[],
            syncSurveyList: [] as TApiSurvey[],
            surveys: [
                // {
                //     jsonObj: '{"pages":[{"name":"page1","elements":[{"type":"radiogroup","name":"question1","title":"2+2=","correctAnswer":"item2","choices":[{"value":"item1","text":"3"},{"value":"item2","text":"4"},{"value":"item3","text":"Без понятия"}]},{"type":"comment","name":"question2","title":"Что думаете о третьей главе?"}]}]}',
                //     apiSurveyId: '10010199-2be2-49fa-9b3c-4f9f3e0ba34e',
                //     urlVar: '',
                //     syncName: 'Опрос третья глава',
                //     apiUpdated: new Date('2021-04-09T21:53:28'),
                //     serverUpdated: new Date('2021-10-07T21:53:28')
                // }
            ] as TSurvey[],
            syncProgressCurrent: 0,
            syncProgressTotal: 0,
        }
    },
    async mounted(){
        await this.getAllSurveysBackend();
        await this.fetchSyncSurveyList();
        this.getAllSurveysResultsBackend();
    },
    methods: {
        reloadPage(){ location.reload(); },
        toDateIfString(d:Date|string|undefined){ return typeof d == 'string' ? new Date(d) : d; },
        async saveSurveys(){
            const urlVars = this.surveys.map(({urlVar})=>urlVar) as (string|undefined)[];
            for (let i = 0; i < urlVars.length; i++) {
                const urlVar = urlVars[i];
                if(!urlVar) {
                    alert('Невозможно сохранить изменения. В одном из опросов не указан url параметр.');
                    return;
                }
                if(urlVars.filter((uVar)=>urlVar == uVar).length > 1){
                    alert('Невозможно сохранить изменения. Url параметр "'+urlVar+'" повторяется в нескольких опросах.');
                    return;
                }
            }

            this.isFetchingSaveSurveys = true;

            try {
                const deletedSurveys = this.surveys
                    .filter(({apiUpdated,apiSurveyId})=>!apiUpdated && this.surveyResultsMap[apiSurveyId].length > 0);
                if(deletedSurveys.length) {
                    alert('Опросы со следующими ID\n'+deletedSurveys.map((surv)=>surv.apiSurveyId).join('\n')+`\n не могут быть удалены. Причина: найдены сохраненные результаты прохождения`);
                }
                const filteredSurveys = this.surveys
                    .filter(({apiUpdated,apiSurveyId})=>!!apiUpdated || this.surveyResultsMap[apiSurveyId].length != 0);
                this.surveys = (await ApiService.post(endpoints.adminPostSaveQuizzes, filteredSurveys)).data.quizzes
                this.surveys.forEach((survey) => survey.serverUpdated = this.toDateIfString(survey.serverUpdated) )
                let surveyEdit:undefined|VueConstructor|VueConstructor[] = this.$refs['survey-edit'];
                if(surveyEdit) {
                    (Array.isArray(surveyEdit) ? surveyEdit : [surveyEdit])
                        .forEach((se:any)=>se.onSave());
                }
                await this.fetchSyncSurveyList();
            } catch (error) {
                alert('При сохранении произошла ошибка '+error)
            } finally {
                this.isFetchingSaveSurveys = false;
            }
        },
        //TODO: Fetch surveys from server, store in surveys[] & serverSurveys
        async fetchSyncSurveyList(){
            if(!this.surveyApiKey || this.surveyApiKey.length < 5 ) return;
            this.isFetchingSurveyList = true;
            try {
                const request = await fetch(endpoints.adminGetSurveyList(this.surveyApiKey), {headers: {Accept: 'application/json, text/json'}});
                let strResponse;
                try {
                    strResponse = await request.text();
                    const json = JSON.parse( strResponse );
                    this.syncSurveyList = json;
                    this.surveyListSyncDate = new Date();
                    const surveyListSyncDateOffset = this.surveyListSyncDate.getTimezoneOffset()*60000 + GMT3_MS_OFFSET;
                    this.surveyListSyncDate.setTime(this.surveyListSyncDate.getTime() + surveyListSyncDateOffset);
                    if(Array.isArray(this.syncSurveyList) && this.syncSurveyList.length > 0) 
                        this.fetchUpdateSurveys(this.syncSurveyList);
                    window.scrollTo({top: 0, behavior: 'auto'});
                } catch (error) {
                    alert("Ответа от SurveyJS: "+strResponse+"\n"+error);
                }
            } catch (error) {
                console.error(error);
                alert(error);
            } finally {
                this.isFetchingSurveyList = false;
            }
        },
        async getAllSurveysResultsBackend(){
            try {
                this.surveyResultsMap = (await ApiService.get(endpoints.adminGetSurveyResults)).data.results
                Object.values(this.surveyResultsMap).forEach((result:any) => result.created = (this.toDateIfString(result.created)) ); 
            } catch (error) {
                alert('При запросе результатов опросов произошла ошибка '+processResponseError(error));
            }
        },
        async getAllSurveysBackend(){
            try {
                const {quizzes} = (await ApiService.get(endpoints.getQuizzes)).data;
                this.serverSurveyList = quizzes;
                this.surveys = quizzes;
                const toDateIfString = (d:Date|string|undefined) => typeof d == 'string' ? new Date(d) : d;
                [...this.surveys, ...this.serverSurveyList]
                    .forEach((survey) => survey.serverUpdated = toDateIfString(survey.serverUpdated) )
            } catch (error) {
                alert(error);
            }
        },
        async fetchUpdateSurveys(syncSurveyList:TApiSurvey[]){
            // if(surveyIds == undefined) surveyIds = this.surveys.map(({apiSurveyId})=>apiSurveyId);
            this.syncProgressTotal = syncSurveyList.length;
            this.syncProgressCurrent = 0;
            for (let i = 0; i < syncSurveyList.length; i++) {
                const {Id: surveyId, Name: surveyName, UpdatedOn: apiUpdatedStr} = syncSurveyList[i];
                
                //Conevrt UTC TO GMT+3
                const apiUpdated = new Date(apiUpdatedStr);
                apiUpdated.setTime(apiUpdated.getTime() + GMT3_MS_OFFSET);

                const savedSurvey = this.surveys.find(({apiSurveyId})=>apiSurveyId == surveyId);
                (async () => {
                    const response = await fetch(endpoints.adminGetPublicSurvey(surveyId));
                    window.scrollTo({top: 0, behavior: 'auto'})
                    const strJson = JSON.stringify( await response.json() );
                    if(savedSurvey){
                        this.$set( savedSurvey, 'jsonObj', strJson);
                        this.$set( savedSurvey, 'syncName', surveyName);
                        this.$set( savedSurvey, 'apiUpdated', apiUpdated);
                    }else {
                        this.surveys.push({
                            apiSurveyId: surveyId,
                            apiUpdated,
                            syncName: surveyName,
                            jsonObj: strJson,
                            urlVar: surveyId,
                            chapter: 1,
                        })
                    }
                    this.syncProgressCurrent++;
                })();
            }
        },
        downloadCsvSurveyResults():void{
            let str = '';

            let forinCounter = 0;
            for (const key in this.surveyResultsMap) {
                if (Object.prototype.hasOwnProperty.call(this.surveyResultsMap, key)) {
                    const resultsArr:TSurveyResult[] = this.surveyResultsMap[key];
                    resultsArr.forEach((r)=> {
                        r.syncName = this.surveys.find((survey)=>survey.apiSurveyId == key)?.syncName || '';
                        r.apiSurveyId = key;
                    } );
                    str += serializeSurveyResults(resultsArr, forinCounter == 0);
                }
                forinCounter++;
            }
            const fileName = `Quiz results `+new Date()
                .toLocaleString('en-us', {year: 'numeric', month: '2-digit', day: '2-digit'})
                .replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');

            downloadFile(str, fileName+'.csv', 'text/csv');
        },
        processQuizResult(results:TSurveyResult[], surveyId:string):string{
            if(results.length == 0) return "Нет результатов";
            results.forEach((result)=>{result.apiSurveyId = surveyId; result.syncName = this.getQuizName(surveyId);})
            return serializeSurveyResults(results, true);
        },
        getQuizName(surveyId:string):string{
            const surveys = this.surveys;
            return (surveys.find((surv)=>surv.apiSurveyId == surveyId)||{}).syncName || 'Опрос с ID ' + surveyId + (this.surveyListSyncDate ? ' удалён' : ' не синхронизирован')
        }
    },
    computed: {
        showChangesAreNotSaved():boolean{
            return false;
        },
    }
});
