type TSurvey = {
    jsonObj: string;
    /**
     * @description surveyJS api survey id (also acts as a backend api)
     */
    apiSurveyId: string;
    urlVar: string;
    /**
     * @description surveyJS api survey name
     */
    syncName: string;
    chapter:number;
    /**
     * @description surveyJS api survey update date
     */
    apiUpdated?:Date;
    /**
     * @description backend api survey update date
     */
    serverUpdated?:Date;
}

type TApiSurvey = {
    Id:string;
    Name: string;
    UpdatedOn: string;
}

type TSurveyResult = {
    resultsJson:string;
    /**
     * @description quiz score. ranges from 1 to 5
     */
    score:number;
    created:Date;
    user:any;
    syncName?:string;
    apiSurveyId?:string;
}

export function serializeSurveyResults(results:TSurveyResult[],addLabels:boolean):string{
    // const pattern = new RegExp('[,|\\n]','g');
    const pattern = new RegExp('"','g');
    let str = addLabels ? 'Название опроса,ID опроса,ID пользователя,Имя пользователя,Дата прохождения опроса,Полученный счёт(1-5),Ответы\n' : '';
    results.forEach(result => {
        const resultObj = JSON.parse(result.resultsJson);
        const replaceAllFun = (s:string, replace:string, replaceWith:string) => (s+"").split(replace).join(replaceWith);
        const cells = [
            (result.syncName||'удаленный опрос'),
            result.apiSurveyId,
            result.user.id,
            result.user.username,
            new Date(result.created).toLocaleString(),
            result.score,
        ]
        str += cells.map((s)=>'"'+replaceAllFun(s, '"', '""')+'"').join(',')
        str += ",";
        const answerStrings = [];
        for (const key in resultObj) {
            if (Object.prototype.hasOwnProperty.call(resultObj, key)) {
                let val:string = resultObj[key];
                val = typeof val == 'string' ? val : JSON.stringify(val);
                answerStrings.push( `"${key}=${replaceAllFun(val, '"', '""')}"`);
            }
        }
        str += answerStrings.join(',')+'\n';
    });
    return str;
}

export {TSurvey, TApiSurvey, TSurveyResult};